import * as React from "react"
import { Formik, Field, Form } from 'formik'
import Seo from "../components/seo"
import { TextArea, Select } from "../components/fields"
import { gql, useMutation, useQuery } from '@apollo/client'
import _ from "lodash"
import styled from "styled-components"

import { Stack } from "/src/components/grid"
import { Button } from "/src/components/button"
import { H1 } from "/src/components/text"
import * as Yup from 'yup'
import { LoginModal } from "/src/components/header"
import { navigate } from "gatsby"

import Header from "/src/components/header"

import * as Text from "/src/components/text"
import { trackEvent } from "/src/utils"

const Container = ({ children }) => {

    return (
        <div style={{
            maxWidth: 480,
            margin: `0 auto`,
            padding: `0 1.0875rem`,
        }}>
            {children}
        </div>
    )
}

const COMPLETE_APPLICATION = gql`
    mutation completeApplication{
        completeApplication{
            error
        }
    }
`

const UPDATE_APPLICATION = gql`
    mutation updateApplication(
        $brandName: String, 
        $businessName: String, 
        $fullName: String, 
        $edrpou: String, 
        $iban: String, 
        $phoneNumber: String, 

        $hasRelocatied: String,
        $relocatedTo: String,
        $hasFemaleFounders: String,
        $workingEmployees: String,
        $femaleEmployees: String,
        $maleEmployees: String,
        $hasRelocatedEmployees: String,
        $hasVeterans: String,
        $hasOldYoung: String,
    ){
        updateApplication(
            brandName: $brandName,
            businessName: $businessName,
            fullName: $fullName,
            edrpou: $edrpou,
            iban: $iban,
            phoneNumber: $phoneNumber,

            hasRelocatied: $hasRelocatied,
            relocatedTo: $relocatedTo,
            hasFemaleFounders: $hasFemaleFounders,
            workingEmployees: $workingEmployees,
            femaleEmployees: $femaleEmployees,
            maleEmployees: $maleEmployees,
            hasRelocatedEmployees: $hasRelocatedEmployees,
            hasVeterans: $hasVeterans,
            hasOldYoung: $hasOldYoung,
        ){
            error
        }
    }
`


const ME = gql`
    query{
        me{
            email
        }
        myApplication{
            brandName
            businessName
            fullName
            edrpou
            iban
            phoneNumber

            hasRelocatied
            relocatedTo
            hasFemaleFounders
            workingEmployees
            femaleEmployees
            maleEmployees
            hasRelocatedEmployees
            hasVeterans
            hasOldYoung
        }
    }
`

const Shield = () => {


    return (
        <div>
            <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="17.1812" cy="17.9346" rx="17.5838" ry="13.082" transform="rotate(-31.2408 17.1812 17.9346)" fill="#F3CF21" />
                <mask id="path-2-inside-1_0_1" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M16 0.000214569C11.8757 3.37932 6.83472 5.68113 1.30685 6.47584C1.10464 7.88232 1 9.32031 1 10.7827C1 21.887 7.03298 31.5821 16 36.7693C24.967 31.5821 31 21.887 31 10.7827C31 9.3203 30.8954 7.88232 30.6932 6.47584C25.1653 5.68113 20.1243 3.37932 16 0.000214569Z" />
                </mask>
                <path d="M16 0.000214572L16.6338 -0.773313L16 -1.29256L15.3662 -0.773313L16 0.000214572ZM1.30685 6.47584L1.16454 5.48602L0.423555 5.59255L0.317025 6.33354L1.30685 6.47584ZM16 36.7693L15.4993 37.6349L16 37.9245L16.5007 37.6349L16 36.7693ZM30.6932 6.47584L31.683 6.33354L31.5764 5.59255L30.8355 5.48602L30.6932 6.47584ZM15.3662 -0.773313C11.3788 2.49369 6.50662 4.71801 1.16454 5.48602L1.44915 7.46567C7.16282 6.64424 12.3726 4.26495 16.6338 0.773742L15.3662 -0.773313ZM0.317025 6.33354C0.108065 7.787 0 9.27254 0 10.7827H2C2 9.36807 2.10122 7.97763 2.29667 6.61815L0.317025 6.33354ZM0 10.7827C0 22.2584 6.23597 32.2763 15.4993 37.6349L16.5007 35.9037C7.83 30.8879 2 21.5156 2 10.7827H0ZM16.5007 37.6349C25.764 32.2763 32 22.2584 32 10.7827H30C30 21.5156 24.17 30.8879 15.4993 35.9037L16.5007 37.6349ZM32 10.7827C32 9.27254 31.8919 7.787 31.683 6.33354L29.7033 6.61815C29.8988 7.97764 30 9.36807 30 10.7827H32ZM30.8355 5.48602C25.4934 4.71801 20.6212 2.49369 16.6338 -0.773313L15.3662 0.773742C19.6274 4.26495 24.8372 6.64424 30.5508 7.46567L30.8355 5.48602Z" fill="black" mask="url(#path-2-inside-1_0_1)" />
                <path d="M15 23L10 17L15 19L22.5 11.5L15 23Z" fill="black" stroke="black" />
            </svg>


        </div>
    )
}


const AutoSaveSuccessStyled = styled.div`
    position: fixed;
    top: ${({ isSaved }) => isSaved ? 16 : - 160}px;
    left: 50%;
    background: #000;
    color: #fff;
    transform: translate(-50%, 0);
    padding: 10px 24px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap:8px;
    transition: all 0.5s ease;
    z-index:100
`

const AutoSaveSuccess = ({ handleHide, isSaved, props }) => {
    React.useEffect(() => {
        const timer = setTimeout(() => { handleHide() }, 3000);
        return () => clearTimeout(timer);
    }, [isSaved, handleHide])
    return (
        <AutoSaveSuccessStyled {...{ ...props, isSaved }}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12.5L1 6.5L6 8.5L13.5 1L6 12.5Z" fill="#D89A23" stroke="#D89A23" />
            </svg>
            <div>Зміни збережено</div>
        </AutoSaveSuccessStyled>
    )
}

const applicationSchema = Yup.object().shape({
    brandName: Yup.string()
        .nullable()
        .required("Обовʼязокове поле")
        .max(30, "Максимум 30 символів"),
    businessName: Yup.string()
        .nullable()
        .required("Обовʼязокове поле")
        .max(30, "Максимум 30 символів"),
    fullName: Yup.string()
        .nullable()
        .min(2, "Мінімум три символи")
        .max(50, "Максимум 50 символів")
        .required("Обовʼязокове поле"),
    edrpou: Yup.string()
        .nullable()
        .required("Обовʼязокове поле")
        .max(1000, "Максимум 1000 символів"),
    iban: Yup.string()
        .nullable()
        .required("Обовʼязокове поле")
        .max(1000, "Максимум 1000 символів"),
    phoneNumber: Yup.string()
        .nullable()
        .required("Обовʼязокове поле")
        .min(6, "Некоректний номер")
        .max(16, "Некоректний номер")
        .matches(/^[\s0-9()+-]+$/, "Некоректний номер"),

    hasRelocatied: Yup.string()
        .nullable()
        .required("Обовʼязокове поле"),
    relocatedTo: Yup.string()
        .nullable(),
    hasFemaleFounders: Yup.string()
        .nullable()
        .required("Обовʼязокове поле"),
    workingEmployees: Yup.string()
        .nullable()
        .matches(/^[0-9]+$/, "Має бути число")
        .required("Обовʼязокове поле"),
    femaleEmployees: Yup.string()
        .nullable()
        .matches(/^[0-9]+$/, "Має бути число")
        .required("Обовʼязокове поле"),
    maleEmployees: Yup.string()
        .nullable()
        .matches(/^[0-9]+$/, "Має бути число")
        .required("Обовʼязокове поле"),
    hasRelocatedEmployees: Yup.string()
        .nullable()
        .required("Обовʼязокове поле"),
    hasVeterans: Yup.string()
        .nullable()
        .required("Обовʼязокове поле"),
    hasOldYoung: Yup.string()
        .nullable()
        .required("Обовʼязокове поле"),
})

const ApplicationForm = () => {
    const [isSaved, setIsSaved] = React.useState(false)
    const [updateApplication] = useMutation(UPDATE_APPLICATION, {
        onCompleted: () => {
            setIsSaved(true)
        }
    })

    const [completeApplication] = useMutation(COMPLETE_APPLICATION, {
        onCompleted: () => {
            trackEvent("application_complete")
        }
    })

    const { data: dataMe, loading } = useQuery(ME)
    const me = _.get(dataMe, "me")
    const applicationInitial = _.get(dataMe, "myApplication", {})
    const handleSubmit = (values) => {
        console.log("values", values)
        updateApplication({
            variables: {
                ...values
            }
        })
    }

    return (
        <div>
            <Header />
            <div style={{ paddingTop: 144 }}></div>


            <Container>
                {loading
                ? <Text.Center>Завантаження...</Text.Center>
                : <div>
                    
                    {!me
                        && <LoginModal setIsLoginModalOn={() => navigate(`/`)} />}
                    <Seo title="Дані компанії" />
                    <AutoSaveSuccess isSaved={isSaved} handleHide={() => setIsSaved(false)} />
                    <Stack gap={24}>
                        <H1 center style={{margin: 0}}>Дані компанії</H1>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ borderRight: "1px solid #C4C4C4", padding: "8px 16px", marginRight: "16px" }}>
                                <Shield />
                            </div>
                            <div>
Ці дані потрібні нам, щоб підготувати індивідуальний договір для надання фінансової допомоги.
Ми також просимо поділитись деталями щодо регіону вашого виробництва та складу команди. 
Будьте уважні, перевірте та відредагуйте у разі необхідності вказані раніше дані. Дякуємо!
                            </div>
                        </div>
                        <Formik
                            enableReinitialize={true}
                            validationSchema={applicationSchema}
                            handleChange={() => {
                                setIsSaved(false)
                            }}
                            initialValues={{
                                businessName: "",
                                ...applicationInitial
                            }}
                            onSubmit={(values) => {
                                setIsSaved(false)
                                handleSubmit(values)
                                setIsSaved(true)
                                completeApplication()
                                _.delay(() => {
                                    navigate(`/success/`)
                                }, 600, 'later');
                            }}
                        >
                            {({ handleBlur, values, dirty, errors }) => {
                                const handleBlurSubmit = (e) => {
                                    handleBlur(e)
                                    if (dirty) {
                                        const cleanValues = _.pickBy(values, (v, k) => !errors[k])
                                        handleSubmit(cleanValues)
                                    }
                                }
                                return (
                                    <Form>

                                        <Stack>
                                            <Field name="brandName">
                                                {({ field, meta }) => (
                                                    <TextArea
                                                        label="1. Назва бренду"
                                                        {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                    />
                                                )}
                                            </Field>

                                            <Field name="businessName">
                                                {({ field, meta }) => (
                                                    <TextArea
                                                        label="2. Назва юридичної особи"
                                                        {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                        helper={`Підказка: Можливо вам доведеться сплатити податок з грошової допомоги. Порадьтеся с бухгалтером, щоб дізнатися точну суму. Для нас також важливо, щоб у вашого бренду не було бенефіціарів у країнах, чия міжнародна стратегія суперечить цілісності України.`}
                                                    />
                                                )}
                                            </Field>

                                            <Field name="fullName">
                                                {({ field, meta }) => {
                                                    return (
                                                        <TextArea
                                                            label="3. ПІБ підписанта"
                                                            {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                            helper={`Прізвище ім'я по батькові представника бренду (людини, яка заповнює заявку)`}
                                                        />
                                                    )
                                                }}
                                            </Field>


                                            <Field name="edrpou">
                                                {({ field, meta }) => (
                                                    <TextArea
                                                        label="4. ЄДРПОУ"
                                                        {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                    />
                                                )}
                                            </Field>


                                            <Field name="iban">
                                                {({ field, meta }) => (
                                                    <TextArea
                                                        label="5. Банківській рахунок (IBAN)"
                                                        {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                    />
                                                )}
                                            </Field>


                                            <Field name="phoneNumber">
                                                {({ field, meta }) => (
                                                    <TextArea
                                                        label="6. Номер мобільного телефону"
                                                        {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                    />
                                                )}
                                            </Field>



                                            <Field name="hasRelocatied">
                                                {({ field, meta }) => (
                                                    <Select {...{
                                                        ...field,
                                                        label: "7. Чи довелось вам перенести виробництво внаслідок повномасштабної війни?",
                                                        error: meta.touched && meta.error,
                                                        handleBlur: handleBlurSubmit,
                                                        options: [
                                                            {

                                                                text: "Так",
                                                                value: "Так",
                                                            },
                                                            {
                                                                text: "Ні",
                                                                value: "Ні"
                                                            }
                                                        ]
                                                    }}
                                                    />
                                                )}
                                            </Field>
                          

                                            <Field name="relocatedTo">
                                                {({ field, meta }) => (
                                                    <TextArea
                                                        label="8. Якщо так, то зазначте у який регіон?"
                                                        {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="hasFemaleFounders">
                                                {({ field, meta }) => (
                                                    <Select {...{
                                                        ...field,
                                                        label: "9. Чи є жінки серед засновників компанії?",
                                                        error: meta.touched && meta.error,
                                                        handleBlur: handleBlurSubmit,
                                                        options: [
                                                            {

                                                                text: "Так",
                                                                value: "Так",
                                                            },
                                                            {
                                                                text: "Ні",
                                                                value: "Ні"
                                                            }
                                                        ]
                                                    }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="workingEmployees">
                                                {({ field, meta }) => (
                                                    <TextArea
                                                        label="10. Скільки зараз у вашій компанії працює людей?"
                                                        {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="femaleEmployees">
                                                {({ field, meta }) => (
                                                    <TextArea
                                                        label="11. Скільки жінок?"
                                                        {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="maleEmployees">
                                                {({ field, meta }) => (
                                                    <TextArea
                                                        label="12. Скільки чоловіків?"
                                                        {...{ ...field, error: meta.touched && meta.error, handleBlur: handleBlurSubmit }}
                                                    />
                                                )}
                                            </Field>
                                            
                                            <Field name="hasRelocatedEmployees">
                                                {({ field, meta }) => (
                                                    <Select {...{
                                                        ...field,
                                                        label: "13. Чи є у команді внутрішньо переміщені особи?",
                                                        error: meta.touched && meta.error,
                                                        handleBlur: handleBlurSubmit,
                                                        options: [
                                                            {

                                                                text: "Так",
                                                                value: "Так",
                                                            },
                                                            {
                                                                text: "Ні",
                                                                value: "Ні"
                                                            }
                                                        ]
                                                    }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="hasVeterans">
                                                {({ field, meta }) => (
                                                    <Select {...{
                                                        ...field,
                                                        label: "14. Чи є у команді ветерани?",
                                                        error: meta.touched && meta.error,
                                                        handleBlur: handleBlurSubmit,
                                                        options: [
                                                            {

                                                                text: "Так",
                                                                value: "Так",
                                                            },
                                                            {
                                                                text: "Ні",
                                                                value: "Ні"
                                                            }
                                                        ]
                                                    }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="hasOldYoung">
                                                {({ field, meta }) => (
                                                    <Select {...{
                                                        ...field,
                                                        label: "15. Чи є у команді молодь/літні люди?",
                                                        error: meta.touched && meta.error,
                                                        handleBlur: handleBlurSubmit,
                                                        options: [
                                                            {

                                                                text: "Так",
                                                                value: "Так",
                                                            },
                                                            {
                                                                text: "Ні",
                                                                value: "Ні"
                                                            }
                                                        ]
                                                    }}
                                                    />
                                                )}
                                            </Field>

                                            {!_.isEmpty(errors) || console.log('errors', errors)
                                                && <div style={{
                                                    color: "#E51010",
                                                    padding: "4px 16px"
                                                }}>Будь ласка, виправте помилки вище</div>}

                                            <Button type="submit" fluid disabled={!_.isEmpty(errors)}>Підтверджую</Button>
                                        </Stack>
                                    </Form>

                                )
                            }}
                        </Formik>
                    </Stack>
                </div>}


            </Container>
        </div>


    )
}

export default ApplicationForm
